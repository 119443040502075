import axios from "axios";

// Function to log in to the SMS service
const loginToSMSService = async () => {
  try {
    const response = await axios.post("https://e-sms.dialog.lk/api/v1/login", {
      username: "vishexuser",
      mask: "VishmithaEx",
      password: "VishEx@123",
    });

    const token = response.data.token; // Assuming the token is returned in the response
    return token;
  } catch (error) {
    console.error("Error logging in to SMS service:", error);
    return null;
  }
};

// Function to send SMS
const sendSMS = async (token, mobileNumber, messageContent) => {
  try {
    const response = await axios.post(
      "https://e-sms.dialog.lk/api/v2/sms",
      {
        msisdn: [{ mobile: mobileNumber }],
        sourceAddress: "VishmithaEx",
        message: messageContent,
        transaction_id: String(Date.now()), // Unique transaction ID
        payment_method: "0",
        push_notification_url: "https://vishmithahighway.lk/",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming Bearer token is needed
        },
      }
    );

    // console.log("SMS sent successfully:", response.data);
  } catch (error) {
    console.error("Error sending SMS:", error);
  }
};

const extractEnglishWord = (text) => {
  const regex = /[a-zA-Z]+/;
  const match = text.match(regex);
  return match ? match[0] : "";
};

const formatSeatGender = (seatGender) => {
  let formattedString = "";
  for (const seat in seatGender) {
    formattedString += `\n\t\t${seat}(${seatGender[seat]})\n`;
  }
  return formattedString;
};

export const sendSMSNow = async (
  bookingDetails,
  total,
  seatGender,
  BusStartTime,
  BusStartFrom
) => {
  const token = await loginToSMSService();

  if (token) {
    let msg =
      "VISHMITHA HIGHWAY EXPRESS\n246/6B, Kumarasinghe Road, Badulla" +
      "\nNF5272\n\nDate: " +
      bookingDetails.date +
      "\nBus start from: " +
      BusStartFrom +
      "\nStart time : " +
      BusStartTime +
      "\nNIC: " +
      (bookingDetails.nic == undefined ? "N/A" : bookingDetails.nic) +
      "\nSEATS :" +
      formatSeatGender(seatGender) +
      "\nONE SEAT: 2190/= \nFROM :" +
      extractEnglishWord(bookingDetails.from) +
      "\nTo :" +
      extractEnglishWord(bookingDetails.to) +
      "\nTotal Amount: " +
      total +
      "/=\n\n+94 766333030 | +94 775333030\nTHANK YOU...\nVisit us for online bookings!\nhttps://vishmithahighway.lk/";
    await sendSMS(token, bookingDetails.mobile_no, msg);
  }
};
