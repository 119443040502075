//Customer booking view
import React, { useState } from "react";
import SeatPicker from "./Config";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import "./styles.css";
import validateSeatSelectionNew from "./validateSeatSelectionNew";
import Grid from "@mui/material/Grid";
import { BsBoxFill } from "react-icons/bs";
import generateView from "./generateView";
import { app_log } from "../../config/logs";
import Loading from "../utilities/Loading/Loading";
import { toast, ToastContainer } from "react-toastify";
import jsPDF from "jspdf";

import LogoImage from "../../assets/images/logo.jpg";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import Switch from "@mui/material/Switch";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import EventSeat from "@mui/icons-material/EventSeat";

import { saveBooking } from "../../services/busServices";
import { sendSMSNow } from "../../services/sendSMS";
import { storage } from "../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

function SeatGrid({ item, userMode, component, pickup, drop, date }) {
  const [selected, setSelected] = useState([]);
  const [seatGender, setSeatGender] = useState({});
  const [paid, setPaid] = useState(false);
  const [came, setCame] = useState(false);
  // console.log(item)
  const [bookingDetails, setBookingDetails] = useState({
    nic: "",
    email: "",
    mobile_no: "",
    passenger_name: "",
    payment_method: "upload_slip",
    payment_slip: "",
    from: pickup,
    to: drop,
    date: date,
    slip: "",
  });

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUploadtoStorage, setImageUploadtoStorage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingDetails((prev) => ({ ...prev, [name]: value }));
  };

  // const leftSeat = 2;
  // const rightSeat = 2;
  // const totalRows = 12;
  // const reservedSeatsByMale = [23, 25, 7, 5];
  // const reservedSeatsByFemale = [37, 38, 39, 54];
  // const removedSeats = [];
  // const counterSeats = [9, 10, 11, 12, 13, 14, 15, 16, 47];
  // const midSpace = 1;

  const leftSeat = item?.structure?.left_seat ?? 0;
  const rightSeat = item?.structure?.right_seat ?? 0;
  const totalRows = item?.structure?.total_rows ?? 0;
  const reservedSeatsByMale = item?.reservedSeatsByMale ?? [];
  const reservedSeatsByFemale = item?.reservedSeatsByFemale ?? [];
  const removedSeats = item?.structure?.removed_seats ?? [];
  const counterSeats = item?.counter_seats ?? [];
  const midSpace = item?.mid_space ?? 1;
  const BusStartTime = item?.depature_time ?? "";
  const BusStartFrom = item?.depature_from ?? "";

  const generatedRows = generateView(
    leftSeat,
    rightSeat,
    totalRows,
    reservedSeatsByMale,
    reservedSeatsByFemale,
    removedSeats,
    counterSeats,
    midSpace
  );

  const [loading, setLoading] = useState(false);
  const [multipleSeat, setMultipleSeat] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  const price = 2190;
  const totalprice = price * selected.length;

  const handleChangeMultipleAllowed = (event) => {
    setSelected([]);
    setSeatGender({});
    setMultipleSeat(event.target.checked);
  };

  const handleGenderChange = (event, seatNumber) => {
    const { value } = event.target;
    setSeatGender((prevSeatGender) => ({
      ...prevSeatGender,
      [seatNumber]: value,
    }));
    console.log(seatGender);
  };

  const addSeatCallback = (row, number, id) => {
    setSelected((prevItems) => [...prevItems, number]);
  };

  const removeSeatCallback = (row, number, id) => {
    const { [number]: _, ...updatedSeatGender } = seatGender;
    setSeatGender(updatedSeatGender);
    setSelected((list) => list.filter((item) => item !== number));
  };

  const uploadImageNow = async () => {
    if (
      bookingDetails.payment_method === "upload_slip" &&
      imageUpload != null
    ) {
      console.log("came-->");
      const imageRef = ref(storage, `others/${imageUpload.name + v4()}`);
      let a = await uploadBytes(imageRef, imageUpload)
        .then(async (snapshot) => {
          const downloadURL = await getDownloadURL(imageRef);
          console.log("Image uploaded! URL:", downloadURL);
          console.log(
            "ImageUploadtoStorage inside uploadImageNow:",
            imageUploadtoStorage
          );
          toast.success("Image uploaded!", {
            position: "top-right",
          });
          return downloadURL;
        })
        .catch((error) => {
          toast.error("Error uploading image", {
            position: "top-right",
          });
          alert("Error uploading image. Please try again.");
        });
      console.log("=====>", a);
      return a;
    }
  };
  const generateBookingPDF = async () => {
    const doc = new jsPDF();

    // Format seatGender object as string
    const seatGenderString = formatSeatGender(seatGender);

    // Define the content for the PDF
    const content = [
      { label: "NIC:", value: bookingDetails.nic },
      { label: "Email:", value: bookingDetails.email },
      { label: "Mobile No:", value: bookingDetails.mobile_no },
      { label: "Passenger Name:", value: bookingDetails.passenger_name },
      { label: "Paid Amount:", value: 2190 * selected.length },
      { label: "From:", value: extractEnglishWord(bookingDetails.from) },
      { label: "To:", value: extractEnglishWord(bookingDetails.to) },
      { label: "Booked Seats:", value: seatGenderString },
    ];

    // Load logo image
    const logo = new Image();
    logo.src = LogoImage;

    // Ensure logo is loaded before generating PDF
    logo.onload = () => {
      // Set initial y position for content
      let y = 20;

      // Add logo and bus name at the top
      doc.addImage(logo, "JPG", 20, 10, 40, 40); // Adjust dimensions and position as needed
      doc.setFontSize(16);
      doc.text("VISHMITHA HIGHWAY EXPRESS", 70, 30); // Adjust position as needed
      y += 50; // Adjust based on logo and bus name height

      // Add title
      doc.setFontSize(16);
      doc.text("Booking Details | " + bookingDetails.date, 20, y);
      y += 10;

      // Add content
      doc.setFontSize(12);
      content.forEach((item) => {
        doc.text(`${item.label} ${item.value}`, 20, y);
        y += 10;
      });

      // Add footer
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          "VISHMITHA HIGHWAY EXPRESS",
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 10,
          { align: "center" }
        );
      }

      // Add current date line after footer
      const currentDate = new Date().toLocaleDateString();
      doc.setFontSize(10);
      doc.text(
        `Generated on: ${currentDate}`,
        20,
        doc.internal.pageSize.height - 20
      );

      // Save the PDF with a meaningful filename
      doc.save("booking_details.pdf");
    };
  };

  // Function to extract first English word from a string
  const extractEnglishWord = (text) => {
    const regex = /[a-zA-Z]+/;
    const match = text.match(regex);
    return match ? match[0] : "";
  };

  // Function to format seatGender object as a string
  const formatSeatGender = (seatGender) => {
    let formattedString = "";
    for (const seat in seatGender) {
      formattedString += `\n\t\t${seat}(${seatGender[seat]})\n`;
    }
    return formattedString;
  };

  const validateBooking = async () => {
    let image = "";
    if (selected.length <= 0) {
      toast.error("Please select one or more seats first", {
        position: "top-right",
      });
      return;
    }
    const firstSeatKey = Object.keys(seatGender)[0];
    const firstSeatGender = seatGender[firstSeatKey];
    if (
      firstSeatGender === undefined ||
      Object.keys(seatGender).length != selected.length
    ) {
      toast.error("Gender selection is mandatory!", {
        position: "top-right",
      });
    } else if (selected.length === 0) {
      toast.error("Please select one or more seats first!", {
        position: "top-right",
      });
    } else if (!multipleSeat) {
      const res = validateSeatSelectionNew(
        firstSeatGender,
        selected,
        reservedSeatsByFemale,
        reservedSeatsByMale
      );

      if (!res) {
        toast.error(
          firstSeatGender === "male"
            ? "You can't select a seat near to the female"
            : "You can't select a seat near to the male",
          {
            position: "top-right",
          }
        );
      } else {
        // Additional validation for user input fields
        if (
          !bookingDetails.nic ||
          !bookingDetails.email ||
          !bookingDetails.mobile_no ||
          !bookingDetails.passenger_name ||
          !bookingDetails.payment_method
        ) {
          toast.error("Please fill in all the required fields!", {
            position: "top-right",
          });
          return;
        }
        console.log(seatGender);

        if (bookingDetails.payment_method === "upload_slip") {
          image = await uploadImageNow();
          console.log("------->", image);

          if (!image) {
            toast.error("Upload payment slip!", {
              position: "top-right",
            });
            return;
          }
        }

        const bookingPayload = {
          bus_number: item.id,
          ...bookingDetails,
          fee: 2190 * selected.length,
          seats: [seatGender],
          payment_slip: image,
          status:
            bookingDetails.payment_method === "upload_slip"
              ? "processing"
              : "booked",
        };
        setLoading(true);
        await saveBooking(bookingPayload)
          .then(async (result) => {
            console.log(!result.success);
            if (!result.success) {
              console.log("cannot book");
              setResponseMessage(result.message);
              setSelected([]);
              setSeatGender({});
              toast.error(result.message, {
                position: "top-right",
              });
              return;
            }
            // After successful booking save:
            await generateBookingPDF();
            await sendSMSNow(
              bookingDetails,
              2190 * selected.length,
              seatGender,
              BusStartTime,
              BusStartFrom
            );
            setResponseMessage(
              "Booking Successful! Downloaded booking details as PDF"
            );
            toast.success(
              "Booking Successful! Downloaded booking details as PDF",
              {
                position: "top-right",
                autoClose: false,
                onClose: () => {},
              }
            );
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            // setLoading(false);
          })
          .catch((error) => {
            console.error("Error saving booking:", error);
            setLoading(false);
            return;
          });
        setLoading(false);
      }
    } else {
      // Additional validation for user input fields
      if (
        !bookingDetails.nic ||
        !bookingDetails.email ||
        !bookingDetails.mobile_no ||
        !bookingDetails.passenger_name ||
        !bookingDetails.payment_method
      ) {
        toast.error("Please fill in all the required fields!", {
          position: "top-right",
        });
        return;
      }
      console.log(seatGender);
      if (bookingDetails.payment_method === "upload_slip") {
        image = await uploadImageNow();
        console.log("------->", image);
        if (!image) {
          toast.error("Upload payment slip!", {
            position: "top-right",
          });
          return;
        }
      }

      const bookingPayload = {
        bus_number: item.id,
        ...bookingDetails,
        fee: 2190 * selected.length,
        seats: [seatGender],
        payment_slip: image,
        status:
          bookingDetails.payment_method === "upload_slip"
            ? "processing"
            : "booked",
      };
      setLoading(true);
      await saveBooking(bookingPayload)
        .then(async (result) => {
          console.log(result);
          if (!result.success) {
            console.log("cannot book");
            setResponseMessage(result.message);
            setSelected([]);
            setSeatGender({});
            toast.error(result.message, {
              position: "top-right",
            });
            return;
          }
          // After successful booking save:
          await generateBookingPDF();
          await sendSMSNow(
            bookingDetails,
            2190 * selected.length,
            seatGender,
            BusStartTime,
            BusStartFrom
          );
          setResponseMessage(
            "Booking Successful! Downloaded booking details as PDF"
          );
          toast.success(
            "Booking Successful!  Downloaded booking details as PDF",
            {
              position: "top-right",
              autoClose: false,
              onClose: () => {},
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error saving booking:", error);
          setLoading(false);
          return;
        });
      setLoading(false);
    }
  };

  // app_log("info", "selected seats", "SeatGrid", { selected, seatGender });

  const acceptPayement = (row, number, id) => {
    if (!paid) {
      console.log("Payment Accepted");
      setPaid(true);
      setCame(true);
      toast.success("Payment Accepted", {
        position: "top-right",
      });
    } else {
      setCame(true);
      console.log("Participation Confirmed");
      toast.info("Participation Confirmed", {
        position: "top-right",
      });
    }
  };

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <div className="seats">
      <ToastContainer />
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Switch
                checked={multipleSeat}
                onChange={handleChangeMultipleAllowed}
              />
            }
            label={multipleSeat ? "Multiple" : "Single"}
            labelPlacement="start"
            sx={{ gap: "8px" }}
          />
        </Grid> */}
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
          <SeatPicker
            key={multipleSeat}
            addSeatCallback={addSeatCallback}
            removeSeatCallback={removeSeatCallback}
            rows={generatedRows}
            alpha
            maxReservableSeats={multipleSeat ? 5 : 1}
            visible
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {userMode ? (
            <FormControlLabel
              control={
                <Switch
                  checked={multipleSeat}
                  onChange={handleChangeMultipleAllowed}
                />
              }
              label={multipleSeat ? "Multiple" : "Single"}
              labelPlacement="start"
              sx={{ gap: "8px", marginBottom: "8px" }}
            />
          ) : (
            ""
          )}
          <List
            subheader={
              <ListSubheader>
                {selected.length === 0
                  ? "No Seats Selected yet"
                  : `${selected.length} Seats Selected ${
                      totalprice !== 0 ? totalprice + " LKR" : ""
                    }`}
              </ListSubheader>
            }
          >
            {selected.map((seat, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <EventSeat />
                </ListItemIcon>
                <ListItemText
                  primary={`No: ${selected[index]}`}
                  sx={{ fontSize: "0.875rem" }} // Reduced font size
                />
                {userMode ? (
                  <RadioGroup
                    aria-label="gender"
                    name={`gender-${seat}`}
                    value={seatGender[seat] || ""}
                    onChange={(event) => handleGenderChange(event, seat)}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: "0.5rem",
                    }} // Reduced font size
                  >
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
                        />
                      } // Reduced icon size
                      label="Male"
                      sx={{ fontSize: "0.5rem" }} // Reduced font size
                    />
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
                        />
                      } // Reduced icon size
                      label="Female"
                      sx={{ fontSize: "0.5rem" }} // Reduced font size
                    />
                  </RadioGroup>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={acceptPayement}
                    disabled={came}
                    sx={{ fontSize: "0.875rem" }} // Reduced font size
                  >
                    {!paid ? "Accept" : !came ? "Confirm" : "Confirmed"}
                  </Button>
                )}
              </ListItem>
            ))}
          </List>
          <TextField
            label="NIC"
            name="nic"
            fullWidth
            margin="normal"
            value={bookingDetails.nic}
            onChange={handleInputChange}
          />
          <TextField
            label="Passenger Name"
            name="passenger_name"
            fullWidth
            margin="normal"
            value={bookingDetails.passenger_name}
            onChange={handleInputChange}
          />
          <TextField
            label="Mobile No"
            name="mobile_no"
            fullWidth
            margin="normal"
            value={bookingDetails.mobile_no}
            onChange={handleInputChange}
          />
          <TextField
            label="Email"
            name="email"
            fullWidth
            margin="normal"
            value={bookingDetails.email}
            onChange={handleInputChange}
          />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Payment Details</FormLabel>
            <List
              subheader={
                <ListSubheader>
                  Mr. B.D.S. Silva <br />
                  Acc No : 79822837 | Bank Of Ceylon - Badulla{" "}
                </ListSubheader>
              }
            />
            <RadioGroup
              row
              name="payment_method"
              value={bookingDetails.payment_method}
              onChange={handleInputChange}
            >
              {/* <FormControlLabel
                value="pay_at_bus"
                control={<Radio />}
                label="Pay at Bus"
              /> */}
              <FormControlLabel
                value="upload_slip"
                control={<Radio />}
                label="Upload Slip"
              />
            </RadioGroup>
            {bookingDetails.payment_method === "upload_slip" && (
              // <input
              //   type="file"
              //   onChange={(event) => {
              //     setImageUpload(event.target.files[0]);
              //   }}
              // ></input>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}
              />
            )}
          </FormControl>
          <p className="text-danger">{responseMessage}</p>
          {userMode ? (
            <Button
              variant="contained"
              color="primary"
              disabled={buttonDisable}
              onClick={async () => {
                setButtonDisable(true);
                await validateBooking(seatGender);
                setButtonDisable(false);
              }}
              style={{ marginTop: "20px" }}
            >
              Booking Now!
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <hr />
    </div>
  );
}

export default SeatGrid;
